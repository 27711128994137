import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-gap: ${p => p.theme.grid.gap};
  grid-template-columns:
    [page-start]
      0px
    [grid-start lyric-start section-start left-start right-start]
      repeat(6, 1fr)
    [grid-end lyric-end section-end left-end right-end]
      0px
    [page-end];
`;
