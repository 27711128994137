import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';

import {PageHeaderDropdownMenu} from '@/components/styleAnchors';
import {zIndex} from '@/style';
import {lightTheme} from '@/style/theme';

const PageHeaderDropdown = ({
  children,
  isOpen,
  className,
}) => {
  const container = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noscroll');
    }

    return () => {
      document.body.classList.remove('noscroll');
    };
  }, [isOpen]);

  return isOpen ? (
    <ThemeProvider theme={lightTheme}>
      <PageHeaderDropdown.Container isMobile className={className} ref={container} height={window.innerHeight}>
        {children}
      </PageHeaderDropdown.Container>
    </ThemeProvider>
  ) : null;
};

export default React.memo(PageHeaderDropdown);

PageHeaderDropdown.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

PageHeaderDropdown.Container = styled(PageHeaderDropdownMenu)`
  background-color: ${p => p.theme.color.background.main};
  overflow: hidden;
  ${zIndex('mobileSubnavigation')}
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${p => p.theme.header.height};
`;
