import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';
import {useTranslation} from 'react-i18next';
import PageHeaderSearch from './PageHeaderSearch';
import PageHeaderAuthLinks from './PageHeaderAuthLinks';
import PageHeaderLoggedIn from './PageHeaderLoggedIn';
import AndroidBanner from './AndroidBanner';
import Cloudflare from './Cloudflare';
import SocialLinks from './SocialLinks';
import HamburgerToggle from './HamburgerToggle';
import PageFooter from './PageFooter';
import {PageHeaderLogo, TextLabel} from '@/components';
import {zIndex} from '@/style';
import {headerTheme, surfaceTheme} from '@/style/theme';
import {useSubnavigationItems} from '@/hooks';

const PageHeader = ({showFooterDropdown}) => {
  const {t} = useTranslation();
  const {loggedIn, links} = useSubnavigationItems();
  const [destination, setDestination] = useState(null);
  const [subnavigationOpen, setSubnavigationOpen] = useState(false);
  const toggleSubnavigation = useCallback(() => setSubnavigationOpen(open => !open), []);

  useEffect(() => {
    if (destination) {
      window.location = destination;
      setDestination(null);
      setSubnavigationOpen(false);
    } else if (subnavigationOpen) {
      document.body.classList.add('noscroll');
    }
    return () => {
      document.body.classList.remove('noscroll');
    };
  }, [subnavigationOpen, destination]);

  return (
    <ThemeProvider theme={headerTheme}>
      <>
        <PageHeader.Container>
          <PageHeader.Left>
            <HamburgerToggle isOpen={subnavigationOpen} toggle={toggleSubnavigation} />
            <PageHeaderSearch />
          </PageHeader.Left>
          <PageHeader.Right>
            {loggedIn ?
              <PageHeaderLoggedIn /> :
              <>
                <PageHeader.Logo />
                <PageHeaderAuthLinks />
              </>
            }
          </PageHeader.Right>
        </PageHeader.Container>

        <AndroidBanner />

        <Cloudflare />

        {subnavigationOpen &&
          <ThemeProvider theme={surfaceTheme}>
            <PageHeader.Subnavigation onClick={toggleSubnavigation}>
              {links.map(({label, action, useLabelTranslation = true}) => action.startsWith('http') ?
                <PageHeader.Link key={label} as="a" href={action} target="_blank" rel="noopener">
                  <TextLabel size="smallReading">{useLabelTranslation ? t(`page_header.section.${label}`) : label}</TextLabel>
                </PageHeader.Link>
                :
                <PageHeader.Link key={label} onClick={() => setDestination(action)}>
                  <TextLabel size="smallReading">{useLabelTranslation ? t(`page_header.section.${label}`) : label}</TextLabel>
                </PageHeader.Link>
              )}
              <PageHeader.Link as="div">
                <SocialLinks size={14} ctx="header" />
              </PageHeader.Link>
              {showFooterDropdown && <PageFooter hideSocialAndSlogan />}
            </PageHeader.Subnavigation>
          </ThemeProvider>
        }
      </>
    </ThemeProvider>
  );
};

PageHeader.propTypes = {
  showFooterDropdown: PropTypes.bool,
};

PageHeader.defaultProps = {
  showFooterDropdown: false,
};

export default React.memo(PageHeader);

PageHeader.Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${p => p.theme.header.height};
  background: ${p => p.theme.color.background.main};
`;

PageHeader.Left = styled.div`
  display: flex;
  align-items: center;
`;

PageHeader.Right = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${p => p.theme.space.full};
`;

PageHeader.Link = styled.div`
  display: block;
  padding: ${p => p.theme.space.small} ${p => p.theme.space.xxLarge};
  line-height: 1;
  color: ${p => p.theme.color.background.on};
  border-bottom: 1px solid ${p => p.theme.color.background.on};
`;

PageHeader.Subnavigation = styled.div`
  ${zIndex('mobileSubnavigation')}
  background: ${p => p.theme.color.background.main};
  position: fixed;
  top: ${p => p.theme.header.height};
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
`;

PageHeader.Logo = styled(props => <PageHeaderLogo {...props} />)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 0;

  svg {
    transform: translateY(-50%);
    height: 17px;
  }
`;
