import React, {useState, useEffect, useCallback} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';

import {
  AuthenticationModal,
  PageHeaderLogo,
  StickyNavLoggedIn,
  StickyNavSearch,
  SocialLinks,
  StickyBannerAd,
  StickyNavSignUp,
  ToastManager,
} from '@/components';
import SiteLink from '@/components/SiteLink.mobile';
import {zIndex} from '@/style';
import {useDistanceAbove, useMixpanelEvent, useSubnavigationItems} from '@/hooks';
import Hamburger from '@/svg/hamburger.svg';
import XSmall from '@/svg/xSmall.svg';
import {stickyNavTheme} from '@/style/theme';

const SPONSORED_LINKS = new Set(['Collective Sounds']);

const StickyNav = ({theme}) => {
  const [isOpen, setOpen] = useState(false);
  const {loggedIn, links} = useSubnavigationItems();

  const trackNavOpen = useMixpanelEvent('song:nav_menu_open');
  const trackNavClose = useMixpanelEvent('song:nav_menu_close');

  const [sponsoredLinks, siteLinks] = partition(links, ({label}) => SPONSORED_LINKS.has(label));

  const distanceAboveBottomStickyNav = useDistanceAbove('bottomStickyNav');

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noscroll');
    }
    return () => {
      document.body.classList.remove('noscroll');
    };
  }, [isOpen]);

  const handleToggle = useCallback(() => {
    if (isOpen) {
      trackNavClose();
    } else {
      trackNavOpen();
    }
    setOpen(!isOpen);
  }, [isOpen, trackNavClose, trackNavOpen]);

  const handleSearch = useCallback(() => setOpen(false), []);

  return (
    <ThemeProvider theme={theme}>
      <ToastManager />
      <StickyNav.Container id="sticky-nav">
        <StickyBannerAd
          name="mobile_song_sticky"
          position="top"
          placeholderSize={{width: 320, height: 50}}
        />
        <StickyNav.NavBar>
          <StickyNav.Left>
            <StickyNav.ToggleIcon isOpen={isOpen} onClick={handleToggle}>
              {isOpen ? <XSmall /> : <Hamburger />}
            </StickyNav.ToggleIcon>
            <StickyNavSearch onSearch={handleSearch} />
          </StickyNav.Left>
          {!loggedIn && (
            <StickyNav.LogoContainer>
              <StickyNav.Logo />
            </StickyNav.LogoContainer>
          )}
          {loggedIn ? <StickyNavLoggedIn /> : <StickyNavSignUp />}
        </StickyNav.NavBar>
      </StickyNav.Container>
      {isOpen && (
        <StickyNav.Navigation
          onClick={handleToggle}
          distanceAboveBottomStickyNav={distanceAboveBottomStickyNav}
        >
          {siteLinks.map(({label, action, useLabelTranslation = true}) =>
            <SiteLink
              key={label}
              label={label}
              action={action}
              useLabelTranslation={useLabelTranslation}
            />
          )}
          {Boolean(sponsoredLinks.length) && (
            <>
              <StickyNav.Divider/>
              {sponsoredLinks.map(({label, action, useLabelTranslation = true}) =>
                <SiteLink
                  key={label}
                  label={label}
                  action={action}
                  useLabelTranslation={useLabelTranslation}
                />
              )}
            </>
          )}
          <StickyNav.Divider />
          <StickyNav.SocialLinks size={14} ctx="nav" />
        </StickyNav.Navigation>
      )}
      <AuthenticationModal />
    </ThemeProvider>
  );
};

StickyNav.propTypes = {
  theme: PropTypes.object,
};

StickyNav.defaultProps = {
  theme: stickyNavTheme,
};

export default React.memo(StickyNav);

StickyNav.Container = styled.nav`
  ${zIndex('stickyNav')};
  display: flex;
  background-color: ${p => p.theme.color.background.main};
  position: sticky;
  top: 0;
  flex-direction: column;
`;

StickyNav.NavBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${p => p.theme.grid.gap};
  width: 100%;
  height: ${p => p.theme.stickyNav.height.mobile};
  background-color: inherit;
  color: ${p => p.theme.color.background.on};
`;

StickyNav.Left = styled.div`
  display: flex;
  align-items: center;
`;

StickyNav.LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

StickyNav.ToggleIcon = styled.div`
  width: 15px;
  margin-right : ${p => p.theme.space.large};
  svg {
    fill: ${p => p.theme.color.background.on};
    margin: 0 auto;
    display: block;
    width: ${p => p.isOpen ? '14px' : '16px'};
  }
`;

StickyNav.Navigation = styled.div`
  ${zIndex('mobileSubnavigation')}
  background: ${p => p.theme.color.background.main};
  position: sticky;
  top: ${p => `calc(${p.distanceAboveBottomStickyNav})`};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${p => p.theme.space.xxLarge};
`;

StickyNav.Divider = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.background.on};
  width: 20px;
  margin-bottom: ${p => p.theme.space.large};
`;

StickyNav.SocialLinks = styled(props => <SocialLinks {...props} />)`
  margin: ${p => p.theme.space.xSmall} 0;
`;

StickyNav.ScrollTopLink = styled.div`
  svg {
    vertical-align: -5px !important;
    fill: currentColor;
  }
`;

StickyNav.Logo = styled(props => <PageHeaderLogo {...props} />)`
  svg {
    height: 12px;
  }
`;
