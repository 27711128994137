import React, {useState, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {PageHeaderDropdown, PageHeaderSearchResults} from '@/components';
import {useDebouncedCallback, useMixpanelEvent} from '@/hooks';
import {zIndex} from '@/style';

import MagnifyingGlass from '@/svg/magnifyingGlass.svg';
import XSmall from '@/svg/xSmall.svg';

const StickyNavSearch = ({onSearch}) => {
  const {t} = useTranslation();
  const [query, setQuery] = useState('');
  const [isOpen, setOpen] = useState(false);

  const inputEl = useRef(null);

  const trackSearchFocus = useMixpanelEvent('song:search_focused');
  const trackSearchCancel = useMixpanelEvent('song:search_canceled');

  const showDropdown = isOpen && Boolean(query.length);

  const debouncedSetQuery = useDebouncedCallback({
    callback: searchText => setQuery(searchText),
    delay: 500,
    dependencies: [],
  });

  const onChange = useCallback(() => {
    const val = inputEl.current.value.trim();
    debouncedSetQuery(val);
  }, [debouncedSetQuery]);

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      setQuery('');
      onSearch();
    } else {
      trackSearchCancel();
    }
    setOpen(!isOpen);
  }, [isOpen, onSearch, trackSearchCancel]);

  if (!isOpen) {
    return (
      <StickyNavSearch.SearchIcon onClick={handleToggle} />
    );
  }
  return (
    <StickyNavSearch.Form>
      <StickyNavSearch.Input
        ref={inputEl}
        onChange={onChange}
        autoComplete="off"
        placeholder={t('page_header.search_bar.placeholder')}
        onFocus={() => trackSearchFocus()}
        autoFocus
        required
      />
      <StickyNavSearch.CloseIcon onClick={handleToggle} />
      <StickyNavSearch.Dropdown isOpen={showDropdown}>
        <PageHeaderSearchResults query={query} />
      </StickyNavSearch.Dropdown>
      {showDropdown && <StickyNavSearch.Background />}
    </StickyNavSearch.Form>
  );
};

export default React.memo(StickyNavSearch);

StickyNavSearch.propTypes = {
  onSearch: PropTypes.func,
};

StickyNavSearch.defaultProps = {
  onSearch: () => {},
};

StickyNavSearch.SearchIcon = styled(MagnifyingGlass)`
  fill: ${p => p.theme.color.background.on};
  width: 14px;
`;

StickyNavSearch.CloseIcon = styled(XSmall)`
  fill: ${p => p.theme.color.background.on};
  width: 12px;
  position: absolute;
  right: 0;
  height: 100%;
  margin-right: ${p => p.theme.grid.gap};
`;

StickyNavSearch.Form = styled.div`
  position: absolute;
  height: ${p => p.theme.stickyNav.height.mobile};
  left: 0;
  right: 0;
  top: 0;
  ${zIndex('stickyNav')};
`;

StickyNavSearch.Input = styled.input`
  background-color: ${p => p.theme.color.background.main};
  color: ${p => p.theme.color.background.on};
  font-size: ${p => p.theme.fontSize.smallReading};
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  padding-left: ${p => p.theme.grid.gap};
  padding-right: ${p => `calc(${p.theme.grid.gap} + ${p.theme.space.full})`};
  -webkit-appearance: none;
  overflow: hidden;
  resize: none;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${p => p.theme.color.background.on};
  };
  ::-ms-input-placeholder {
    color: ${p => p.theme.color.background.on};
  };
`;

StickyNavSearch.Dropdown = styled(PageHeaderDropdown)`
  position: relative;
  top: ${p => p.theme.stickyNav.height.mobile};
`;

StickyNavSearch.Background = styled.div`
  background: white;
  height: 100vh;
`;
