import React from 'react';
import PropTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';
import {TextLabel, PageFooterSocial, PageFooterArtistLinks, PageFooterHotSongLinks} from '@/components';
import {useRedux, FooterGridMobile, FooterGridCenter} from './PageFooter.shared';
import isFunction from 'lodash/isFunction';
import {useTranslation} from 'react-i18next';
import {surfaceTheme} from '@/style/theme';

const PageFooter = ({className, hideSocialAndSlogan}) => {
  const {t} = useTranslation();
  const {links} = useRedux();
  return (
    <ThemeProvider theme={surfaceTheme}>
      <PageFooter.Section as="footer" className={className}>
        <FooterGridCenter>
          {hideSocialAndSlogan ||
            <PageFooter.Social>
              <PageFooterSocial/>
            </PageFooter.Social>
          }

          {links.map((section, i) =>
            <PageFooter.LinkSection key={i}>
              {section.map(
                ({label, action, className: linkClassName}) => isFunction(action) ?
                  <PageFooter.Link onClick={action} key={label} className={linkClassName}>{t(`page_footer.section.${label}`)}</PageFooter.Link> :
                  <PageFooter.Link as="a" href={action} key={label} rel="noopener" className={linkClassName}>{t(`page_footer.section.${label}`)}</PageFooter.Link>
              )}
            </PageFooter.LinkSection>
          )}

          <PageFooter.VerifiedArtists href="/verified-artists">
            <TextLabel size="smallReading">{t('role.types.verified_artist.other')}</TextLabel>
          </PageFooter.VerifiedArtists>
          <PageFooter.ArtistsLabel>
            <TextLabel size="smallReading">{t('page_footer.all_artists')}</TextLabel>
          </PageFooter.ArtistsLabel>
          <PageFooter.Artists>
            <PageFooterArtistLinks/>
          </PageFooter.Artists>
          <PageFooter.HotSongsLabel>
            <TextLabel size="smallReading">{t('page_footer.hot_songs.title')}</TextLabel>
          </PageFooter.HotSongsLabel>
          <PageFooter.HotSongs>
            <PageFooterHotSongLinks/>
          </PageFooter.HotSongs>

          <PageFooter.FinePrint as="a" href="/static/terms">
            {t('page_footer.fine_print.terms')}
          </PageFooter.FinePrint>
          <PageFooter.FinePrint>
            {`© ${new Date().getFullYear()} ${t('page_footer.fine_print.company')}`}
          </PageFooter.FinePrint>
        </FooterGridCenter>
      </PageFooter.Section>
    </ThemeProvider>
  );
};

PageFooter.propTypes = {
  className: PropTypes.string,
  hideSocialAndSlogan: PropTypes.bool,
};

PageFooter.defaultProps = {
  hideSocialAndSlogan: false,
};

export default React.memo(PageFooter);

PageFooter.Section = styled(FooterGridMobile)`
  background: ${p => p.theme.color.background.main};
  color: ${p => p.theme.color.background.on};
  padding: ${p => p.theme.space.xxLarge} 0;
`;

PageFooter.Social = styled.div`
  margin-bottom: ${p => p.theme.space.half};
`;

PageFooter.Link = styled.a`
  display: block;
  color: inherit;
  font-weight: 100;
`;

PageFooter.LinkSection = styled.div`
  padding: ${p => p.theme.space.xLarge} 0;
  border-bottom: 1px solid ${p => p.theme.color.background.on};

  &:first-child {
    padding-top: 0;
  }

  ${PageFooter.Link} + ${PageFooter.Link} {
    margin-top: ${p => p.theme.space.full};
  }
`;

PageFooter.VerifiedArtists = styled.a`
  display: block;
  margin-top: ${p => p.theme.space.xxLarge};
  color: inherit;
  text-align: center;
`;

PageFooter.ArtistsLabel = styled.div`
  text-align: center;
  margin-top: ${p => p.theme.space.xxLarge};
`;

PageFooter.Artists = styled.div`
  margin: ${p => p.theme.space.full} 0 ${p => p.theme.space.xxLarge};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

PageFooter.HotSongsLabel = styled.div`
  text-align: center;
  margin-top: ${p => p.theme.space.xxLarge};
`;

PageFooter.HotSongs = styled.div`
  margin: ${p => p.theme.space.full} 0 ${p => p.theme.space.xxLarge};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

PageFooter.FinePrint = styled.div`
  display: block;
  color: ${p => p.theme.color.background.onVariant};
  font-weight: 100;
  font-size: ${p => p.theme.fontSize.xSmallReading};
  text-align: center;
  margin-top: ${p => p.theme.space.xLarge};
`;
