import React, {useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import PageHeaderInboxUnreadCount from './PageHeaderInboxUnreadCount';
import {useCurrentUser, useInboxCounts} from '@/hooks';
import snakeCase from 'lodash/snakeCase';
import {messageOpened, newInboxEvent, inboxMarkedRead} from '@/actions';
import {capitalize} from '@/util';

const PageHeaderInbox = ({name, icon, syncCountToTitle, abbreviateAfter}) => {
  const currentUser = useCurrentUser();
  const channelName = currentUser.pusherChannel;
  const unreadCount = currentUser[`unread${capitalize(name)}Count`];

  const dispatch = useDispatch();
  const onMessageOpen = useCallback(
    messagesRead => dispatch(messageOpened({messagesRead, userId: currentUser.id})),
    [dispatch, currentUser.id]);

  const onNewEvent = useCallback(
    () => dispatch(newInboxEvent({userId: currentUser.id})),
    [dispatch, currentUser.id]);

  const onMarkedRead = useCallback(
    () => dispatch(inboxMarkedRead({inbox: name, userId: currentUser.id})),
    [dispatch, name, currentUser.id]);

  useInboxCounts({name, channelName, onNewEvent, onMessageOpen, onMarkedRead, syncCountToTitle, unreadCount});

  const Icon = icon;
  return (
    <PageHeaderInbox.Container as="a" href={`/inboxes/${snakeCase(name)}`}>
      <Icon />
      <PageHeaderInboxUnreadCount unreadCount={unreadCount} abbreviateAfter={abbreviateAfter} />
    </PageHeaderInbox.Container>
  );
};

PageHeaderInbox.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  syncCountToTitle: PropTypes.bool,
  abbreviateAfter: PropTypes.number,
};

export default React.memo(PageHeaderInbox);

PageHeaderInbox.Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: ${p => p.theme.header.height};

  svg {
    fill: ${p => p.theme.color.background.on};
    height: 18px;
    width: 18px;
  }
`;
