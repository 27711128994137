import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Link, TextLabel} from '@/components';
import {useMixpanelEvent} from '@/hooks';

const SiteLink = ({label, action, useLabelTranslation}) => {
  const {t} = useTranslation();
  const trackClick = useMixpanelEvent('song:nav_link_clicked', {label});
  return (
    <SiteLink.Container
      key={label}
      href={action}
      target={action.startsWith('http') ? '_blank' : null}
      rel="noopener"
      underline={false}
      onClick={trackClick}
    >
      <TextLabel size="smallReading" fontWeight="light" textTransform="capitalize">
        {useLabelTranslation ? t(`page_header.section.${label}`) : label}
      </TextLabel>
    </SiteLink.Container>
  );
};

export default React.memo(SiteLink);

SiteLink.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  useLabelTranslation: PropTypes.bool,
};

SiteLink.defaultProps = {
  useLabelTranslation: true,
};

SiteLink.Container = styled(props => <Link {...props} />)`
  line-height: ${p => p.theme.lineHeight.medium};
  color: ${p => p.theme.color.background.on};
  margin-bottom: ${p => p.theme.space.large};
`;
