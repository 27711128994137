import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';
import ReactModal from 'react-modal';
import {useTranslation} from 'react-i18next';

import {zIndex} from '@/style';
import {animationEaseOutQuadratic} from '@/style/mixins';
import {IconButton} from '@/components';
import X from '@/svg/x.svg';
import Checkmark from '@/svg/checkmark.svg';
import LeftArrow from '@/svg/leftArrow.svg';

import {
  ModalSharedButtonLabel,
  ModalSharedContainer,
  useModalVisibility,
  onAfterOpen,
  scrollAfterClose,
} from './Modal.shared';

const CLOSE_ICONS = {
  'x': X,
  'leftArrow': LeftArrow,
};

const Modal = ({
  backgroundOpacity,
  bodyId,
  children,
  closeIcon,
  closeLabel,
  hideControls,
  isSaveActive,
  onAfterClose,
  onClose,
  onSave,
  overlayId,
  saveLabel,
  show,
}) => {
  const {t} = useTranslation();
  const {isOpen, scrollY} = useModalVisibility(show);

  const CloseComponent = CLOSE_ICONS[closeIcon];

  const onAfterModalClose = useCallback(() => {
    scrollAfterClose(scrollY);
    if (onAfterClose) {
      onAfterClose();
    }
  }, [onAfterClose, scrollY]);

  return (
    <ReactModal
      bodyOpenClassName="noscroll"
      htmlOpenClassName="noscroll"
      id={bodyId}
      isOpen={isOpen}
      onAfterOpen={() => onAfterOpen(scrollY)}
      onAfterClose={onAfterModalClose}
      onRequestClose={onClose}
      ariaHideApp={false}
      overlayElement={({onClick, style, ...props}, content) => ( // eslint-disable-line no-unused-vars
        <Modal.Container {...props} id={overlayId} backgroundOpacity={backgroundOpacity} onClick={onClick} data-ignore-on-click-outside>
          {content}
        </Modal.Container>
      )}
      contentElement={({style, ...props}, modalChildren) => ( // eslint-disable-line no-unused-vars
        <Modal.Body {...props}>
          {modalChildren}
        </Modal.Body>
      )}
    >
      {!hideControls && (
        <Modal.Controls>
          {onSave && (
            <Modal.ButtonField>
              <Modal.SaveIconButton
                aria-label={saveLabel || t('controls.modal.save')}
                onClick={onSave}
                disabled={!isSaveActive}
              >
                <Checkmark />
              </Modal.SaveIconButton>
              {saveLabel && <ModalSharedButtonLabel>{saveLabel}</ModalSharedButtonLabel>}
            </Modal.ButtonField>
          )}
          <Modal.ButtonField>
            <IconButton aria-label={t('controls.modal.close')} onClick={onClose}>
              <CloseComponent />
            </IconButton>
            {closeLabel && <ModalSharedButtonLabel>{closeLabel}</ModalSharedButtonLabel>}
          </Modal.ButtonField>
        </Modal.Controls>
      )}
      <Modal.Contents>{children}</Modal.Contents>
    </ReactModal>
  );
};

Modal.propTypes = {
  backgroundOpacity: PropTypes.number,
  bodyId: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeIcon: PropTypes.oneOf(['x', 'leftArrow']),
  closeLabel: PropTypes.string,
  hideControls: PropTypes.bool,
  isSaveActive: PropTypes.bool,
  onAfterClose: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  overlayId: PropTypes.string,
  saveLabel: PropTypes.string,
  show: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  backgroundOpacity: 0.7,
  closeIcon: 'x',
  hideControls: false,
  isSaveActive: true,
};

export default React.memo(Modal);

const slideToView = keyframes`
  from { transform: translateX(100%); }
  to   { transform: translateX(0); }
`;

Modal.Container = styled(ModalSharedContainer)`
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, ${p => p.backgroundOpacity});
`;

Modal.Body = styled.div`
  position: relative;
  overflow-x: hidden;
  width: calc(100% - 3rem);
  max-width: 30rem;
  display: flex;
  outline: none;
`;

Modal.Contents = styled.div`
  width: 100%;
  animation: ${slideToView} .2s normal forwards ${animationEaseOutQuadratic};

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

Modal.Controls = styled.div`
  position: fixed;
  width: 3rem;
  padding-top: ${p => p.theme.space.small};
  ${zIndex('modalUpper')};
  text-align: center;
  transform: translateX(-100%);
  font-family: ${p => p.theme.font.alternate};
`;

Modal.ButtonField = styled.label`
  display: block;
  margin-bottom: ${p => p.theme.space.full};
  color: white;
`;

Modal.SaveIconButton = styled(props => <IconButton {...props} />)`
  background-color: ${p => p.theme.color.success.main};
  border-color: transparent;

  &:hover {
    background-color: ${p => p.theme.color.success.main};

    svg {
      fill: white;
    }
  }

  &:disabled {
    pointer-events: none;
    background-color: ${p => p.theme.color.background.onVariant};
  }
`;
