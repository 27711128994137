import React, {useState, useCallback, useEffect} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {android} from 'bowser';
import Playstore from '@/svg/playstore.svg';
import X from '@/svg/x.svg';
import {AppConfig} from '@/config';
import Cookie from 'js-cookie';
import {useMixpanelEvent} from '@/hooks';
import sample from 'lodash/sample';
import {headerTheme} from '@/style/theme';

const androidBannerCookie = 'android_banner_clicked';

const benefits = [
  'to identify songs on the go',
  'to get lyrics to the songs you play',
  'to read lyrics offline',
];

const AndroidBanner = () => {
  const trackBannerClick = useMixpanelEvent('home:android_banner_clicked');
  const trackBannerClose = useMixpanelEvent('home:android_banner_dismissed');
  const [showBanner, setShowBanner] = useState(false);
  const [benefit] = useState(sample(benefits));

  useEffect(() => {
    const shouldHideBanner = Cookie.get(androidBannerCookie);
    setShowBanner(android && !shouldHideBanner);
  }, []);

  const hideBannerAndSetCookie = () => {
    setShowBanner(false);
    Cookie.set(androidBannerCookie, 1, {expires: 30});
  };

  const clickBanner = useCallback(() => {
    hideBannerAndSetCookie();
    trackBannerClick();
  }, [trackBannerClick]);

  const dismissBanner = useCallback(() => {
    hideBannerAndSetCookie();
    trackBannerClose();
  }, [trackBannerClose]);

  return showBanner ? (
    <ThemeProvider theme={headerTheme}>
      <AndroidBanner.Container>
        <AndroidBanner.Content href={AppConfig.playStoreUrl} target="_blank" onClick={clickBanner}>
          <AndroidBanner.Download>
            <Playstore />Download the Android app
          </AndroidBanner.Download>
          <div>{benefit}</div>
        </AndroidBanner.Content>
        <AndroidBanner.CloseButton onClick={dismissBanner}>
          <X />
        </AndroidBanner.CloseButton>
      </AndroidBanner.Container>
    </ThemeProvider>
  ) : null;
};

export default React.memo(AndroidBanner);

AndroidBanner.Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${p => p.theme.color.background.main};
  padding:  ${p => p.theme.space.full};
  padding-top: ${p => p.theme.space.xSmall};
`;

AndroidBanner.Content = styled.a`
  border: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.on};
  padding: ${p => p.theme.space.quarter} ${p => p.theme.space.half};
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.color.background.on};
  svg {
    height: ${p => p.theme.fontSize.xSmallReading};
  }
`;

AndroidBanner.Download = styled.div`
  font-weight: bold;
`;

AndroidBanner.CloseButton = styled.div`
  margin-left: ${p => p.theme.space.full};
  svg {
    fill: ${p => p.theme.color.background.on};
    height: ${p => p.theme.fontSize.reading};
    display: block;
  }
`;
