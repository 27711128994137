import React, {useState, useCallback, useRef} from 'react';
import styled from 'styled-components';
import MagnifyingGlass from '@/svg/magnifyingGlass.svg';
import {PageHeaderDropdown, PageHeaderSearchResults} from '@/components';
import {useHeaderItem, useDebouncedCallback} from '@/hooks';
import {zIndex} from '@/style';
import Close from '@/svg/x.svg';
import {useTranslation} from 'react-i18next';

const PageHeaderSearch = () => {
  const {isOpen, open, close} = useHeaderItem('search');

  const [showInput, setShowInput] = useState(false);
  const [query, setQuery] = useState('');
  const formElement = useRef(null);
  const inputElement = useRef(null);
  const {t} = useTranslation();

  const debouncedSetQuery = useDebouncedCallback({
    callback: searchText => setQuery(searchText),
    delay: 500,
    dependencies: [],
  });

  const onClick = useCallback((event) => {
    event.preventDefault();
    setShowInput(!showInput);

    if (isOpen) {
      close();
    }
  }, [isOpen, showInput, close]);

  const onChange = useCallback(() => {
    const searchInputText = inputElement.current.value.trim();
    if (searchInputText) {
      isOpen || open();
      debouncedSetQuery(searchInputText);
    } else {
      close();
    }
  }, [close, open, isOpen, debouncedSetQuery]);

  return (
    <PageHeaderSearch.Form ref={formElement} action="/search" method="get" showInput={showInput}>
      {showInput &&
        <PageHeaderSearch.Input
          onChange={onChange}
          name="q"
          ref={inputElement}
          autoComplete="off"
          required
          placeholder={t('page_header.search_bar.placeholder')}
          autoFocus
        />
      }
      <PageHeaderSearch.Icon onClick={onClick} >
        {showInput ? <Close /> : <MagnifyingGlass />}
      </PageHeaderSearch.Icon>
      <PageHeaderDropdown
        isOpen={isOpen}
      >
        <PageHeaderSearchResults query={query} />
      </PageHeaderDropdown>
    </PageHeaderSearch.Form>
  );
};

export default React.memo(PageHeaderSearch);

PageHeaderSearch.Form = styled.form`
  display: flex;
  align-items: center;
  left: 0;
  background: ${p => p.theme.color.background.main};
  height: ${p => p.theme.header.height};

  ${p => p.showInput && `
    width: 100%;
    position: absolute;
    padding: ${p.theme.space.half} ${p.theme.space.quarter};
    ${zIndex('mobileSearchInput')}
  `}
`;

PageHeaderSearch.Icon = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${p => `0 ${p.theme.space.half}`};

  svg {
    fill: ${p => p.theme.color.background.on};
    position: relative;
    width: ${p => p.showInput ? '12px' : '18px'};
    top: 1px;
  }
`;

PageHeaderSearch.Input = styled.input`
  flex-grow: 1;
  position: relative;
  top: 2px;
  height: 24px;
  border: none;
  border-radius: 0;
  background: none;
  font-family: ${p => p.theme.font.accent};
  font-feature-settings: ${p => p.theme.font.programmeAlternates};
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  -webkit-appearance: none;
  overflow: hidden;
  resize: none;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${p => p.theme.color.background.on};
  };
  ::-ms-input-placeholder {
    color: ${p => p.theme.color.background.on};
  };
  &:focus::placeholder {
    color: ${p => p.theme.color.background.onVariant};
  };
  padding: ${p => `0 ${p.theme.space.hair} 0 ${p.theme.space.half}`};
`;
